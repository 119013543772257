@use "_styles.scss";

.content__shopping {
    padding: 0 0 30px 0;

    @media only screen and (min-width: 1200px) {
        padding: 0 0 60px 0;
    }

    .shopping-wizard {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;

        &__separator {
            padding: 0 10px;
            @media only screen and (min-width: 991px) {
                padding: 0 20px;
            }

            img {
                width: 14px;
                height: 14px;
                display: block;

                @media only screen and (min-width: 991px) {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        &__step {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 0;
            text-decoration: none;

            @media only screen and (min-width: 991px) {
                padding: 40px 0;
            }

            img {
                max-height: 20px;
                display: block;
            }
        }

        &__caption {
            white-space: nowrap;
            padding: 0 0 0 10px;
            line-height: 1.2;
            @media only screen and (max-width: 550px) {
                display: none;
            }
            @media only screen and (min-width: 768px) {
                padding: 0 0 0 15px;
            }
            @media only screen and (min-width: 991px) {
                padding: 0 0 0 20px;
            }

            strong {
                display: block;
                font-size: 18px;
                font-weight: 500;
                @media only screen and (min-width: 768px) {
                    font-size: 20px;
                }
                @media only screen and (min-width: 991px) {
                    font-size: 25px;
                }
            }

            small {
                display: none;
                font-size: 12px;
                font-weight: normal;

                @media only screen and (min-width: 768px) {
                    display: block;
                }
            }
        }
    }

    .shopping-cart {
        padding: 10px 0 0 0;

        @media only screen and (min-width: 1200px) {
            padding: 10px 0 0 0;
        }
    }

    .cart {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;
        margin: 0;

        thead {
            @media only screen and (max-width: 767px) {
                display: none;
            }

            tr {
                th {
                    text-align: left;
                    font-size: 12px;
                    font-weight: 600;
                    padding: 4px 0;
                }
            }
        }

        tbody {
            tr {
                @media only screen and (max-width: 767px) {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                }

                td {
                    padding: 10px 0;

                    &:first-child {
                        width: 100%;
                        @media only screen and (max-width: 767px) {
                            padding-bottom: 0;
                        }
                        @media only screen and (min-width: 768px) {
                            width: 70%;
                        }
                        @media only screen and (min-width: 1200px) {
                            width: 75%;
                        }
                    }

                    &:nth-child(2) {
                        @media only screen and (max-width: 767px) {
                            width: 100px;
                        }
                    }

                    &:nth-child(3) {
                        @media only screen and (max-width: 767px) {
                            width: calc(100% - 145px);
                        }
                    }

                    &:nth-child(4) {
                        @media only screen and (max-width: 767px) {
                            width: 45px;
                        }
                    }
                }
            }
        }

        &__item {
            display: flex;
            align-items: center;
            text-decoration: none;
            padding: 0 20px 0 0;
            min-height: 40px;
        }

        &__image {
            width: 46px;
            text-align: center;
            margin: 0 18px 0 0;

            img {
                display: inline-block;
                max-width: 46px;
                max-height: 46px;
            }
        }

        &__name {
            text-align: left;
            font-size: 15px;
            font-weight: 600;
            line-height: 1.2;
            transition: 0.3s;

            @media only screen and (min-width: 991px) {
                font-size: 16px;
            }

            small {
                display: block;
                font-size: 12px;
                font-weight: 400;
                margin: 4px 0 0 0;
            }
        }

        &__price {
            line-height: 1.2;
            white-space: nowrap;

            @media only screen and (max-width: 767px) {
                text-align: right;
            }

            strong {
                font-size: 15px;
                font-weight: 600;

                @media only screen and (min-width: 991px) {
                    font-size: 16px;
                }
            }

            small {
                display: block;
                font-size: 12px;
                font-weight: 400;
                margin: 4px 0 0 0;
            }
        }

        &__quantity {
            display: flex;
            height: 36px;
            width: 100%;
            max-width: 80px;
            margin: 0 20px 0 0;
        }

        &__quantity-field {
            appearance: none;
            font-family: styles.$font;
            font-size: 15px;
            font-weight: 600;
            line-height: 1.1;
            padding: 0 10px 0 0;
            margin: 0;
            outline: none !important;
            text-align: center;
            flex: 1;
            min-width: 10px;
            background: transparent;

            @media only screen and (min-width: 991px) {
                font-size: 16px;
            }
        }

        &__quantity-controls {
            display: flex;
            flex-direction: column;

            .up,
            .down {
                flex: 1;
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: center;
                width: 32px;
                transition: 0.3s;
                opacity: 0.4;

                img {
                    max-height: 11px;
                    max-width: 11px;
                }

                &:hover {
                    opacity: 1;
                }
            }

            .up {
                align-items: flex-end;
            }

            .down {
                align-items: flex-start;
            }
        }

        &__remove {
            text-decoration: none;
            display: inline-block;
            margin: 0 10px 0 20px;
            padding: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none !important;
            transition: 0.3s;
            transition: 0.3s;
            opacity: 0.4;

            &:hover {
                opacity: 1;
            }

            img {
                max-width: 12px;
                max-height: 12px;
                display: block;

                @media only screen and (min-width: 991px) {
                    max-width: 15px;
                    max-height: 15px;
                }
            }
        }
    }

    .recap {
        border-spacing: 0;
        border-collapse: collapse;
        margin: 0;
        width: 100%;

        @media only screen and (min-width: 768px) {
            max-width: 34rem;
        }

        thead {
            tr {
                th {
                    text-align: left;
                    font-size: 12px;
                    font-weight: 600;
                    padding: 4px 0;
                }
            }
        }

        tbody {
            tr {
                &:first-child {
                    td {
                        padding: 15px 0 7px 0;
                    }
                }

                td {
                    padding: 7px 0;
                    font-size: 12px;
                    font-weight: 500;

                    strong {
                        font-size: 15px;
                        font-weight: 600;

                        @media only screen and (min-width: 991px) {
                            font-size: 16px;
                        }
                    }

                    &:nth-child(2) {
                        text-align: right;
                        padding-left: 15px;
                    }
                }
            }
        }
    }

    .shopping-recap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        padding: 30px 0 0 0;

        @media only screen and (min-width: 1200px) {
            padding: 60px 0 0 0;
        }

        &__left {
            @media only screen and (max-width: 768px) {
                width: 100%;
                padding: 0 0 30px 0;
            }
        }

        &__form {
            width: 100%;
            padding: 0 0 30px 0;
            @media only screen and (min-width: 768px) {
                max-width: calc(100% - 34rem);
                padding: 0 30px 0 0;
            }
            @media only screen and (min-width: 1200px) {
                padding: 0 60px 0 0;
            }
        }

        &__right {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;

            @media only screen and (max-width: 768px) {
                width: 100%;
            }
        }

        &__box {
            background-color: #f1f1f1;
            padding: 3rem;
            font-size: 15px;
            color: #4e4e4e;

            a {
                text-decoration: underline;
                cursor: pointer;
                background-color: transparent;
                color: #4e4e4e;

                &:hover {
                    color: #004CAB;
                }
            }
        }

        &__box-parent {
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media only screen and (min-width: 768px) {
                width: calc(50% - 20px);
            }
            @media only screen and (min-width: 991px) {
                width: calc(50% - 40px);
            }
        }
    }

    .coupon {
        &__field {
            appearance: none;
            font-family: styles.$font;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.5;
            padding: 10px 15px;
            margin: 0;
            outline: none !important;
        }

        &__submit {
            appearance: none;
            font-family: styles.$font;
            font-size: 12px;
            font-weight: 600;
            line-height: 1.1;
            padding: 10px 15px;
            margin: 0;
            outline: none !important;
            text-align: center;
            text-decoration: underline;
            transition: 0.3s;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .shopping-controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 30px 0 0 0;
        @media only screen and (max-width: 550px) {
            flex-direction: column-reverse;
        }
        @media only screen and (min-width: 1200px) {
            padding: 60px 0 0 0;
        }

        &--last-step {
            padding: 20px 0 0 0;
        }

        &__back,
        &__next {
            font-weight: bold;
            cursor: pointer;
            text-decoration: none;
            outline: none !important;
            appearance: none;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 20px;
            font-size: 15px;
            margin: 0;
            line-height: 1.1;
            transition: 0.3s;
            text-transform: uppercase;

            @media only screen and (max-width: 550px) {
                width: 100%;
            }
            @media only screen and (min-width: 768px) {
                min-width: 34rem;
                padding: 20px 20px;
            }
            @media only screen and (min-width: 991px) {
                font-size: 16px;
            }

            img {
                display: block;
                width: 18px;
                height: 18px;
            }
        }

        &__back {
            img {
                margin: 0 10px 0 0;
                transform: rotate(180deg);

                @media only screen and (min-width: 768px) {
                    margin: 0 24px 0 0;
                }
            }
        }

        &__next {
            @media only screen and (max-width: 550px) {
                margin: 0 0 15px 0;
            }

            img {
                margin: 0 0 0 10px;
                @media only screen and (min-width: 768px) {
                    margin: 0 0 0 24px;
                }
            }
        }
    }

    .shopping-methods {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding: 10px 0 0 0;
        @media only screen and (min-width: 768px) {
            flex-direction: row;
        }
        @media only screen and (min-width: 1200px) {
            padding: 10px 0 0 0;
        }

        &__col {
            width: 100%;

            @media only screen and (min-width: 768px) {
                width: calc(50% - 20px);
            }

            @media only screen and (min-width: 991px) {
                width: calc(50% - 40px);
            }

            &:first-child {
                @media only screen and (max-width: 767px) {
                    margin: 0 0 25px 0;
                }
            }
        }

        &__title {
            text-align: left;
            font-size: 12px;
            font-weight: 600;
            padding: 4px 0;
        }

        &__list {
            border: none;
            margin: 0;
            padding: 0;
        }
    }

    .method-item {
        &.disabled {
            .method-item__radio {
                filter: grayscale(100%);
                opacity: 0.3;
            }
        }

        &__radio {
            min-height: 60px;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-weight: 500;
            font-size: 15px;
            position: relative;
            padding: 10px 0 10px 15px;
            cursor: pointer;
            line-height: 16px;
            outline: none;
            max-width: 100%;

            &:hover {
                text-decoration: none;
            }

            input {
                position: absolute;
                z-index: -1;
                opacity: 0;
            }

            input:checked ~ .method-item__indicator:after {
                display: block;
            }

            .method-item__indicator {
                top: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                height: 14px;
                width: 14px;

                &::after {
                    content: "";
                    position: absolute;
                    display: none;
                    left: 3px;
                    top: 0px;
                    width: 6px;
                    height: 9px;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }
            }
        }

        &__icon {
            width: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            @media only screen and (min-width: 550px) {
                width: 74px;
            }

            img {
                max-width: calc(100% - 20px);
                max-height: 30px;
            }
        }

        &__label {
            width: calc(100% - 44px - 90px);

            @media only screen and (min-width: 550px) {
                width: calc(100% - 74px - 90px);
            }
        }

        &__price {
            width: 90px;
            text-align: right;
        }
    }

    .shopping-secure {
        padding: 25px 15px;

        &__title {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0 0 20px 0;

            img {
                display: block;
                margin: 0 15px 0 0;
                max-height: 30px;
            }

            span {
                font-weight: normal;
                line-height: 1.2;
                font-size: 13px;
            }
        }

        &__cards {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0 0 9px 0;

            img {
                max-height: 16px;
                display: inline-block;
                margin: 6px 9px;
            }
        }

        &__banks {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 100%;

            img {
                max-height: 12px;
                display: inline-block;
                margin: 6px 9px;
            }
        }
    }

    .shopping-login {
        width: 100%;
        padding: 0 0 30px 0;
        @media only screen and (min-width: 768px) {
            max-width: calc(100% - 34rem);
            padding: 0 30px 0 0;
            margin: 0 0 -15px 0;
        }
        @media only screen and (min-width: 1200px) {
            padding: 0 60px 0 0;
            margin: 0 0 -35px 0;
        }

        &__type {
            width: 100%;
            padding: 30px 0 0 0;

            @media only screen and (min-width: 1200px) {
                padding: 60px 0 0 0;
            }
        }

        &__button {
            outline: none !important;
            cursor: pointer;
            transition: 0.3s;
            text-decoration: none;
            display: inline-block;
            font-family: styles.$font;
            font-size: 15px;
            font-weight: 500;
            padding: 15px 20px;
            text-align: center;
            margin: 0 0 10px 0;
            min-width: 100%;

            @media only screen and (min-width: 550px) {
                min-width: 200px;
                margin: 0 10px 10px 0;
            }

            @media only screen and (min-width: 991px) {
                font-size: 16px;
                padding: 20px;
                min-width: 245px;
            }

            &.selected {
            }
        }

        &__fields {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
        }
    }

    .shopping-form {
        &__title {
            text-align: left;
            font-size: 12px;
            font-weight: 600;
            padding: 4px 0;
        }

        &__fields {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            padding: 20px 0 0 0;
        }
    }

    .for-company {
        width: 100%;

        &__fields {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
        }
    }

    .form-submit {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        &__btn {
            cursor: pointer;
            text-decoration: none;
            outline: none !important;
            appearance: none;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 20px;
            font-size: 15px;
            font-weight: 500;
            margin: 0;
            line-height: 1.1;
            transition: 0.3s;

            @media only screen and (max-width: 550px) {
                width: 100%;
            }
            @media only screen and (min-width: 768px) {
                min-width: 24rem;
                padding: 20px 20px;
            }
            @media only screen and (min-width: 991px) {
                font-size: 16px;
            }

            img {
                display: block;
                width: 18px;
                height: 18px;
                margin: 0 0 0 10px;
                @media only screen and (min-width: 768px) {
                    margin: 0 0 0 24px;
                }
            }
        }
    }

    .form-field {
        position: relative;
        width: 100%;
        margin: 0 0 15px 0;

        @media only screen and (min-width: 550px) {
            width: calc(50% - 7px);
        }

        @media only screen and (min-width: 768px) {
            width: 100%;
        }

        @media only screen and (min-width: 991px) {
            width: calc(50% - 7px);
        }

        &--full-w {
            width: 100%;
        }

        &.m-0 {
            margin: 0;
        }

        &--area {
            & > img {
                top: 18px !important;
                transform: none !important;
            }
        }

        a {
            color: inherit;
        }

        & > img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;
            z-index: 10;
            pointer-events: none;
            max-width: 18px;
            max-height: 18px;
        }

        & > input,
        & > textarea {
            padding: 0 10px 0 48px;
            width: 100%;
            min-height: 60px;
            font-family: styles.$font;
            font-size: 13px;
            font-weight: 400;
            outline: none !important;
        }

        & > textarea {
            padding: 15px 10px 15px 48px;
            min-height: 160px;
            resize: none;
        }

        &.error {
            &:hover {
                .error-label {
                    opacity: 1;
                }
            }
        }

        .error-label {
            pointer-events: none;
            opacity: 0;
            transition: 0.3s;
            position: absolute;
            top: calc(100% + 5px);
            left: 15px;
            z-index: 10;
            font-size: 13px;
            font-weight: 500;
            display: inline-block;
            padding: 8px 10px;

            &:before {
                content: "";
                position: absolute;
                top: -5px;
                left: 10px;
            }
        }

        &__checkbox {
            min-height: 50px;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-weight: 500;
            font-size: 15px;
            position: relative;
            padding: 0 0 0 25px;
            cursor: pointer;
            line-height: 16px;
            outline: none;
            max-width: 100%;

            &:hover {
                text-decoration: none;
            }

            input {
                position: absolute;
                z-index: -1;
                opacity: 0;
            }

            input:checked ~ .form-field__indicator:after {
                display: block;
            }

            .form-field__indicator {
                top: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                height: 14px;
                width: 14px;

                &::after {
                    content: "";
                    position: absolute;
                    display: none;
                    left: 3px;
                    top: 0px;
                    width: 6px;
                    height: 9px;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }
            }
        }
    }

    .shopping-done {
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        @media only screen and (min-width: 1200px) {
            padding: 60px;
        }

        &__icon {
            margin: 0 0 25px 0;

            img {
                width: 70px;
                height: 70px;

                @media only screen and (min-width: 991px) {
                    width: 110px;
                    height: 110px;
                }
            }
        }

        &__title {
            font-weight: 600;
            font-size: 20px;
            line-height: 1.2;
            padding: 0;
            margin: 0 0 25px 0;

            @media only screen and (min-width: 991px) {
                font-size: 24px;
            }
        }

        &__text {
            font-weight: 400;
            font-size: 15px;
            line-height: 1.6;
            margin: 0 auto 25px auto;
            width: 100%;
            max-width: 560px;
        }

        &__controls {
        }

        &__next {
            cursor: pointer;
            text-decoration: none;
            outline: none !important;
            appearance: none;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 20px;
            font-size: 15px;
            font-weight: 500;
            margin: 0;
            line-height: 1.1;
            transition: 0.3s;

            @media only screen and (max-width: 550px) {
                width: 100%;
            }
            @media only screen and (min-width: 768px) {
                min-width: 24rem;
                padding: 20px 20px;
            }
            @media only screen and (min-width: 991px) {
                font-size: 16px;
            }

            img {
                display: block;
                width: 18px;
                height: 18px;
                margin: 0 0 0 10px;
                @media only screen and (min-width: 768px) {
                    margin: 0 0 0 24px;
                }
            }
        }
    }
}

.free-delivery {
    width: 100%;

    @media only screen and (max-width: 767px) {
        margin-top: 1rem;
    }

    th {
        border-bottom: 3px solid #004CAB;
        color: #4e4e4e;
        text-align: left;
        font-size: 12px;
        font-weight: 600;
        padding: 4px 0;
    }

    td {
        padding: 10px 0;

        &.td-progress {
            @media only screen and (min-width: 768px) {
                display: flex;
                align-items: center;
            }
        }
    }

    .progress {
        width: 100%;
        height: 1rem;
        overflow: hidden;
        font-size: .75rem;
        background-color: #e9ecef;
        border-radius: .25rem;
        margin-bottom: 1rem;
        display: flex;

        .progress-bar {
            background-color: red;

            &.fullfilled {
                background-color: #00AC39;
            }
        }

        @media only screen and (min-width: 768px) {
            display: inline-flex;
            margin-right: 3rem;
            width: 80%;
            margin-bottom: 0;
        }
    }

    .progress-text {
        vertical-align: text-bottom;
        white-space: nowrap;
        font-size: 12px;
        font-weight: 600;
        color: #4e4e4e;
    }
}

.delivery-change {
    background-color: transparent;
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    font-family: Maven Pro,sans-serif;
    color: #4e4e4e;
    cursor: pointer;
    padding: 0;

    &:hover {
        text-decoration: underline;
        color: #004CAB;
    }
}
