$FONT_CONTENT: "Avenir";

$COLOR_PRIMARY: #004CAB;
$COLOR_PRIMARY_TEXT: #fff;
$COLOR_SECONDARY: #FFBC03;
$COLOR_SECONDARY_TEXT: #004CAB;

*,
*::before,
*::after {
    box-sizing: border-box;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

html {
    font-family: sans-serif;
    font-size: 10px;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background: #ffffff;
}
body {
    font-family: $FONT_CONTENT;
    line-height: 1;
    margin: 0;
    padding: 0;
}

.page {
    overflow: hidden;
}

// Fix some sizes
.form-field,
.form-errors,
.swal2-popup {
    font-size: 16px !important;
}

.form-errors {
    list-style: none;
    color: #ff0000;
    padding-left: 0;
}

.form__contact__agreeterms {
    width: 30%;
}

.footer {
    .form-field__checkbox:hover .form-field__label {
        color: #ffffff;
    }
}


button {
    border: none;
}

.btn {
    outline: none !important;
    border: none;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
    padding: 0;
    font-family: $FONT_CONTENT;
}

.w-100 {
    width: 100%;
}

.container {
    width: 100%;
    margin: 0 auto;
    max-width: 1186px;
    padding: 0 1.5rem;
}

.header-panel {
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 76px;

    @media only screen and (min-width: 991px) {
        height: 96px;
    }
}

.header-dropdown {
    position: relative;
    &.open {
        .header-dropdown__select {
            top: calc(100% - 5px);
            opacity: 1;
            visibility: visible;
        }
    }
    &__toggle {
        display: inline-block;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        background: transparent;
        color: #8c8c8c;
        padding: 15px;

        span {
            pointer-events: none;
        }

        &:hover {
            color: #FFBC03;
        }
    }
    &__select {
        display: block;
        position: absolute;
        top: calc(100% + 15px);
        right: 0;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        padding: 10px 0;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
        z-index: 2;

        @media only screen and (max-width: 990px) {
            right: 50%;
            transform: translateX(50%);
        }
    }
    &__option {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-decoration: none;
        color: #8c8c8c;
        padding: 5px 15px;
        white-space: nowrap;
        display: block;
        transition: 0.3s;

        &:hover {
            color: #FFBC03;
        }
    }
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background: #fff;
    padding: 0;
    @media only screen and (min-width: 1200px) {
        position: absolute;
        top: 70px;
        padding: 0;
        background: transparent;
    }

    &__mobile {
        text-align: center;
        display: block;
        padding: 7px 0 10px 0;

        @media only screen and (min-width: 991px) {
            display: none;
        }

        .header-dropdown {
            display: inline-block;
        }
    }

    .container {
        padding: 0;

        @media only screen and (min-width: 1200px) {
            padding: 0 1.5rem;
        }
    }

    &.fixed {
        position: fixed;
        top: 0;
        background: #fff;
        box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);

        .header-panel {
            height: 76px;

            .header__icon {
                padding: 9px 12px;
                @media only screen and (min-width: 991px) {
                    padding: 9px 18px;
                }
            }
        }
    }

    &__left,
    &__right {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__right {
        padding: 0 15px 0 0;
        @media only screen and (min-width: 991px) {
            padding: 0 15px 0 0;
        }

        & > .header-dropdown {
            display: none;
            @media only screen and (min-width: 991px) {
                display: block;
            }
        }
    }

    &__nav {
        display: none;
        &.open {
            display: block;
            background: #fff;
        }
        @media only screen and (max-width: 990px) {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            padding: 0 1rem 1rem 1rem;
            box-shadow: 0 3rem 3rem rgba(0, 0, 0, 0.1);
        }
        @media only screen and (min-width: 991px) {
            display: block !important;
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        position: relative;
        margin: 0;
    }
    &__icon {
        padding: 9px 12px;
        height: 100%;
        border-right: 1px solid #FFBC03;
        display: block;
        text-decoration: none;
        @media only screen and (min-width: 991px) {
            padding: 20px 12px;
        }
        @media only screen and (min-width: 1200px) {
            padding: 20px 18px;
        }

        img {
            max-width: 5rem;
        }
    }
    &__caption {
        padding: 0 0 0 15px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        text-transform: uppercase;
        color: #004CAB;
        display: none;
        text-decoration: none;
        @media only screen and (min-width: 550px) {
            display: block;
        }
        @media only screen and (min-width: 1200px) {
            padding: 0 0 0 24px;
            font-size: 24px;
            line-height: 30px;
        }
    }

    &__controls {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 0 10px;
        @media only screen and (min-width: 991px) {
            padding: 0 15px;
        }
        @media only screen and (min-width: 1200px) {
            gap: 18px;
            padding: 0 20px;
        }

        .btn-shop {
            display: none;

            @media only screen and (min-width: 991px) {
                display: inline-block;
            }
        }
    }

    &__toggle {
        display: inline-block;
        position: relative;
        background: #086aa7;
        transition: 0.3s ease-in-out;
        padding: 0;
        height: 47px;
        width: 55px;

        @media only screen and (min-width: 991px) {
            display: none;
        }

        span {
            display: block;
            position: absolute;
            height: 0.2rem;
            width: 2.8rem;
            background: #fff;
            opacity: 1;
            left: 13px;
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;
            pointer-events: none;
            &:nth-child(1) {
                top: 14px;
            }
            &:nth-child(2) {
                top: 22px;
            }
            &:nth-child(3) {
                top: 22px;
            }
            &:nth-child(4) {
                top: 30px;
            }
        }
        &.open {
            span {
                &:nth-child(1) {
                    top: 1.5rem;
                    width: 0%;
                    left: 50%;
                }
                &:nth-child(2) {
                    transform: rotate(45deg);
                }
                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
                &:nth-child(4) {
                    top: 1.5rem;
                    width: 0%;
                    left: 50%;
                }
            }
        }
    }
}

.navigation {
    list-style: none;
    margin: 0;
    padding: 0;
    & > li {
        margin: 0;
        padding: 0;
        display: block;
        position: relative;
        @media only screen and (min-width: 991px) {
            display: inline-block;
            vertical-align: middle;
        }
        & > a {
            display: block;
            position: relative;
            cursor: pointer;
            font-weight: normal;
            font-size: 20px;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;
            color: #004CAB;
            transition: 0.5s;
            text-decoration: none;
            text-align: center;
            padding: 10px;
            @media only screen and (min-width: 991px) {
                padding: 0 10px;
                font-size: 16px;
                line-height: 20px;
            }
            @media only screen and (min-width: 1200px) {
                padding: 0 20px;
            }
            span {
                position: relative;
                z-index: 2;
            }

            &:hover {
                color: #FFBC03;
            }
        }
        &.selected {
            & > a {
                font-weight: bold;
            }
        }
    }
}

.content {
    position: relative;
    z-index: 50;
}

.content__home-intro {
    background: url(../images/bg-header.jpg) no-repeat top center;
    background-size: cover;
    padding: 60px 0 198px 0;
    @media only screen and (min-width: 991px) {
        padding: 96px 0 198px 0;
    }
    @media only screen and (min-width: 1200px) {
        padding: 165px 0 243px 0;
    }
}

.home-intro {
    display: flex;
    flex-wrap: wrap;
    //align-items: center;
    justify-content: space-between;

    &__content {
        width: 100%;
        text-align: center;
        padding: 60px 0 0 0;
        @media only screen and (min-width: 768px) {
            width: 62%;
            text-align: left;
        }
        @media only screen and (min-width: 1200px) {
            padding: 110px 0 0 0;
        }
    }
    &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
        color: #004CAB;
        padding: 0;
        margin: 0 0 15px 0;
        @media only screen and (min-width: 991px) {
            font-size: 38px;
            line-height: 44px;
        }
        @media only screen and (min-width: 1200px) {
            font-size: 48px;
            line-height: 64px;
        }
    }
    &__text {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        color: #004CAB;
        @media only screen and (min-width: 991px) {
            font-size: 20px;
            line-height: 28px;
        }
        @media only screen and (min-width: 1200px) {
            font-size: 24px;
            line-height: 32px;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            color: #004CAB;
            margin: 0 0 30px 0;
            @media only screen and (min-width: 991px) {
                font-size: 20px;
                line-height: 28px;
                margin: 0 0 45px 0;
            }
            @media only screen and (min-width: 1200px) {
                font-size: 24px;
                line-height: 32px;
                margin: 0 0 70px 0;
            }
        }

        & > *:last-child {
            margin: 0;
            display: flex;
            justify-content: center;
            @media only screen and (min-width: 768px) {
                justify-content: flex-start;
            }
        }
    }

    &__image {
        width: 38%;
        background: url(../images/oil-splash.png) no-repeat top center;
        background-size: contain;
        background-blend-mode: luminosity;
        filter: grayscale(100%);
        display: none;
        @media only screen and (min-width: 768px) {
            display: block;
        }
    }
}

.footer {
    background: #004CAB;
    padding: 60px 0 0 0;

    .form-field__label,
    .form-field label {
        color: #ffffff;
    }
    .form-field__checkbox:hover {
        text-decoration: none;
    }

    &__top {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        border-bottom: 1px solid #FFBC03;
        padding: 0 0 42px 0;
        row-gap: 24px;
        @media only screen and (min-width: 1200px) {
            row-gap: 0;
        }
    }
    &__logo {
        display: flex;
        align-items: center;
        flex: 1;
        @media only screen and (max-width: 550px) {
            margin: 0 auto;
            width: 100%;
            justify-content: center;
        }
    }
    &__logo-ico {
        display: flex;
        align-items: center;
        height: 70px;
        padding: 0 18px 0 0;
        border-right: 1px solid #fff;

        @media only screen and (min-width: 1200px) {
            height: 96px;
        }

        img {
            max-width: 7rem;
        }
    }
    &__logo-caption {
        padding: 0 15px;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        text-transform: uppercase;
        color: #ffffff;
        @media only screen and (min-width: 768px) {
            font-size: 24px;
            line-height: 30px;
            padding: 0 0 0 23px;
        }
    }
    &__contact {
        display: flex;
        align-items: center;
        width: 100%;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        padding: 0;
        margin: 0 auto;
        max-width: 240px;
        @media only screen and (min-width: 550px) {
            width: 50%;
            padding: 0 0 0 20px;
            margin: 0;
            max-width: 100%;
        }
        @media only screen and (min-width: 768px) {
            font-size: 24px;
            line-height: 30px;
        }
        @media only screen and (min-width: 1200px) {
            flex: 1;
            width: auto;
        }

        img {
            margin: 0 14px 0 0;
        }

        a {
            display: flex;
            align-items: center;
            color: inherit;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 35px 0;
    }
    &__menu {
        padding: 0;
        margin: 0 0 35px 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        width: 100%;
        text-align: center;
        @media only screen and (min-width: 768px) {
            width: 33.33%;
            text-align: left;
            padding: 0 30px 0 0;
        }
        @media only screen and (min-width: 1200px) {
            width: 18%;
        }

        &--2 {
            width: 100%;
            @media only screen and (min-width: 768px) {
                width: 66.66%;
            }
            @media only screen and (min-width: 1200px) {
                width: 34%;
            }

            ul {
                @media only screen and (min-width: 768px) {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 25px;
                    li {
                        width: calc(50% - 13px);
                    }
                }
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin: 0 0 12px 0;
                padding: 0;

                a {
                    color: inherit;
                    text-decoration: none;
                    display: inline-block;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &__menu-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        color: #ffffff;
        margin: 0 0 15px 0;
        padding: 0;
        text-align: center;

        @media only screen and (min-width: 768px) {
            text-align: left;
            font-size: 24px;
            line-height: 30px;
            margin: 0 0 25px 0;
        }
    }

    &__newsletter {
        margin: 0 0 35px 0;
        width: 100%;
        @media only screen and (min-width: 768px) {
            width: 66.66%;
        }
        @media only screen and (min-width: 1200px) {
            width: 30%;
        }
    }

    &__copy {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        padding: 0 0 30px 0;
        @media only screen and (max-width: 767px) {
            row-gap: 15px;
        }
        @media only screen and (min-width: 768px) {
            padding: 0 0 70px 0;
            column-gap: 30px;
            flex-direction: row;
        }
    }

    &__company {
        font-weight: bold;
        color: #ffffff;
        font-size: 14px;
        line-height: 18px;
        @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .copyright {
        font-weight: bold;
        color: #ffffff;
        font-size: 14px;
        line-height: 18px;
        @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
        }

        a {
            color: inherit;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.newsletter {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 400px;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 767px) {
        margin: 0 auto;
    }

    &__input {
        width: 100%;
        font-family: $FONT_CONTENT;
        margin: 0 0 16px 0;
        padding: 14px;
        font-size: 16px;
        color: #000;
        border-radius: 0;
        border: none;
        background: #fff;
    }

    &__check {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #ffffff;
        position: relative;
        padding: 0 20px 0 27px;
        flex: 1;

        input {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            margin: 0;
            padding: 0;
        }
    }

    &__btn {
        background: #FFBC03;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: #004CAB;
        padding: 14px 37px;

        @media only screen and (max-width: 550px) {
            padding: 14px 25px;
        }

        &:hover {
            background: darken(#FFBC03, 10%);
        }
    }
}

.btn-big {
    background: #FFBC03;
    color: #004CAB;
    box-shadow: 0px 24px 28px rgba(254, 210, 75, 0.2);
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 25px 15px 35px;
    display: flex;
    align-items: center;
    position: relative;
    transition: color 0.3s;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    overflow: hidden;

    @media only screen and (min-width: 991px) {
        font-size: 24px;
        line-height: 30px;
        padding: 20px 25px 20px 45px;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 100%;
        left: 0;
        z-index: -1;
        -webkit-transition: -webkit-transform 0.4s;
        transition: transform 0.4s;
        -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
        transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    }

    &::before {
        background: #086aa7;
    }

    &::after {
        background: #004CAB;
    }

    &:hover {
        color: #fff;

        &::before,
        &::after {
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }

        &::after {
            -webkit-transition-delay: 0.175s;
            transition-delay: 0.175s;
        }

        img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(201deg)
                brightness(109%) contrast(101%);
        }
    }

    img {
        margin: 0 0 0 15px;
    }
}

.btn-shop {
    display: inline-block;
    background: #FFBC03;
    color: #004CAB;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    padding: 14px 22px;
    &:hover {
        background: darken(#FFBC03, 10%);
    }
}

.btn-user {
    display: inline-block;
    background: #00AC39;
    padding: 13px 17px;
    position: relative;

    &:hover {
        background: darken(#00AC39, 10%);
    }

    .count {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        background: #ff0000;
        color: #fff;
        width: 27px;
        height: 27px;
        line-height: 27px;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        border-radius: 50%;
    }
}

.btn-cart {
    display: inline-block;
    background: #00AC39;
    padding: 13px 17px;
    position: relative;

    &:hover {
        background: darken(#00AC39, 10%);
    }

    .count {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        background: #ff0000;
        color: #fff;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        border-radius: 50%;

        @media only screen and (min-width: 991px) {
            width: 27px;
            height: 27px;
            line-height: 27px;
            font-size: 16px;
        }
    }
}

.btn-primary {
    display: inline-block;
    background: #FFBC03;
    color: #004CAB;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    padding: 14px 22px;
    &:hover {
        background: darken(#FFBC03, 10%);
        color: #004CAB !important;
    }
}

.btn-secondary {
    display: inline-block;
    background: #004CAB;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    padding: 14px 22px;
    &:hover {
        background: darken(#004CAB, 10%);
        color: #fff !important;
    }
}

.content__nav {
    margin: -148px 0 0 0;
}

.home-nav {
    width: calc(100% + 24px);
    margin: 0 -12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__item {
        width: 100%;
        padding: 0 12px;
        margin: 0 0 34px 0;
        @media only screen and (min-width: 400px) {
            width: 50%;
        }
        @media only screen and (min-width: 768px) {
            width: 33.33%;
        }
    }

    &__switch {
        display: flex;
        justify-content: space-between;
        text-align: center;
        padding-bottom: 3rem;
        color: #004CAB;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        text-transform: uppercase;

        &-radio {
            flex: 1;
            padding-bottom: 2rem;
            position: relative;
            text-decoration: none;
            color: #004CAB;

            &.selected {
                &::after {
                    height: 8px;
                }
            }

            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 8px;
                width: 0;
                background: #004CAB;
                transition: 0.4s;
                z-index: 10;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                background: #FFBC03;
                z-index: 9;
            }

            &:hover {
                color: #FFBC03;

                &::before {
                    width: 100%;
                }
            }
        }
    }

    &__text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #004CAB;
        width: 100%;
        padding-top: 2rem;

        h2 {
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 40px;
            color: #004CAB;
            margin: 0 0 20px 0;
            padding: 0;
            text-align: center;
            @media only screen and (min-width: 550px) {
                font-size: 36px;
                line-height: 45px;
            }
        }

        hr {
            border-top: 1px solid #FFBC03;
            margin: 0 0 20px 0;
        }

        ul {
            margin: 0 0 20px 0;
            padding: 0;
            list-style: none;
            li {
                margin: 0 0 12px 0;
                padding: 0 0 0 20px;
                position: relative;
                font-size: 16px;
                line-height: 24px;
                color: #83a6c9;
                &::before {
                    content: "";
                    width: 9px;
                    height: 9px;
                    background: #d4d638;
                    position: absolute;
                    top: 7px;
                    left: 0;
                    border-radius: 100%;
                }
            }

            a {
                color: #83a6c9;

                &:hover {
                    color: #FFBC03;
                }
            }
        }

        a {
            color: #004CAB;

            &:hover {
                color: #FFBC03;
            }
        }
    }

    &__buttons {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem;
    }

    &__parent {
        padding: 0 0 40px 0;

        @media only screen and (min-width: 991px) {
            padding: 0 0 70px 0;
        }
        &.hidden {
            display: none;
        }
    }
}

.nav-card {
    text-decoration: none;
    color: #004CAB;
    transition: 0.3s;
    &__image {
        padding-top: 55%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-bottom: 8px solid#FFBC03;
        transition: 0.3s;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 8px;
            width: 0%;
            background: #004CAB;
            transform: translateY(100%);
            transition: 0.4s;
        }
    }
    &__title {
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        text-transform: uppercase;
        color: inherit;
        margin: 10px 0 0 0;
        padding: 0;

        @media only screen and (min-width: 991px) {
            margin: 15px 0 0 0;
            font-size: 24px;
            line-height: 30px;
        }
    }

    &:hover {
        color: #FFBC03;
        .nav-card__image {
            &::before {
                width: 100%;
            }
        }
    }
}

.content__about {
    background: #fffcf1;
    padding: 45px 0;

    @media only screen and (min-width: 991px) {
        padding: 95px 0;
    }
}

.about {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    &__map {
        width: 100%;
        text-align: center;
        @media only screen and (min-width: 768px) {
            width: 50%;
        }

        img {
            width: 100%;
            @media only screen and (max-width: 767px) {
                max-width: 450px;
            }
        }
    }

    &__content {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #004CAB;
        width: 100%;
        padding: 40px 0 0 0;
        @media only screen and (min-width: 768px) {
            width: 50%;
            padding: 0 0 0 40px;
        }
        @media only screen and (min-width: 991px) {
            padding: 0 0 0 100px;
        }

        h2 {
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 40px;
            color: #004CAB;
            margin: 0 0 20px 0;
            padding: 0;
            @media only screen and (min-width: 550px) {
                font-size: 36px;
                line-height: 45px;
            }
        }

        hr {
            border-top: 1px solid #FFBC03;
            margin: 0 0 20px 0;
        }

        ul {
            margin: 0 0 20px 0;
            padding: 0;
            list-style: none;
            li {
                margin: 0 0 12px 0;
                padding: 0 0 0 20px;
                position: relative;
                font-size: 16px;
                line-height: 24px;
                color: #83a6c9;
                &::before {
                    content: "";
                    width: 9px;
                    height: 9px;
                    background: #d4d638;
                    position: absolute;
                    top: 7px;
                    left: 0;
                    border-radius: 100%;
                }
            }
        }
    }
}

.content__process {
    &--yellow {
        background: #fffcf1;
    }
}

.process {
    padding: 55px 0;
    @media only screen and (min-width: 991px) {
        padding: 115px 0 130px 0;
    }
    &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 38px;
        color: #004CAB;
        margin: 0 0 70px 0;
        padding: 0;
        text-align: center;
        @media only screen and (min-width: 550px) {
            font-size: 36px;
            line-height: 45px;
        }
        @media only screen and (min-width: 991px) {
            margin: 0 0 130px 0;
        }
    }
    &__controls {
        display: flex;
        justify-content: center;
    }
}

.process-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    &__step {
        margin: 0 0 40px 0;
        width: 50%;
        position: relative;
        padding: 0 15px;
        @media only screen and (min-width: 550px) {
            width: 33.33%;
        }
        @media only screen and (min-width: 768px) {
            flex: 1;
            width: auto;
        }
        @media only screen and (min-width: 991px) {
            margin: 0 0 90px 0;
        }

        &::before {
            content: "";
            position: absolute;
            top: 30px;
            right: 0;
            width: 38px;
            height: 3px;
            background: #FFBC03;
            transform: translateX(50%);

            @media only screen and (min-width: 991px) {
                width: 47px;
            }
        }
        &:last-child {
            &::before {
                content: none;
            }
        }
        @media only screen and (max-width: 549px) {
            &:nth-child(2n) {
                &::before {
                    content: none;
                }
            }
        }
        @media only screen and (min-width: 550px) and (max-width: 767px) {
            &:nth-child(3n) {
                &::before {
                    content: none;
                }
            }
        }
    }
}

.process-step {
    text-align: center;

    &__icon {
        margin: 0 0 30px 0;
        height: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media only screen and (min-width: 991px) {
            margin: 0 0 50px 0;
            height: 60px;
        }

        img {
            max-height: 40px;
            @media only screen and (min-width: 991px) {
                max-height: 60px;
            }
        }
    }

    &__text {
        width: 100%;
        margin: 0 auto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #004CAB;

        @media only screen and (min-width: 991px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.content__news {
    background: #e6f2f1;
    padding: 40px 0;
    @media only screen and (min-width: 550px) {
        padding: 55px 0;
    }
    @media only screen and (min-width: 991px) {
        padding: 80px 0 120px 0;
    }
}

.news {
    &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        color: #004CAB;
        margin: 0 0 35px 0;
        padding: 0;
        text-align: center;
        @media only screen and (min-width: 550px) {
            font-size: 36px;
            line-height: 45px;
        }
        @media only screen and (min-width: 991px) {
            margin: 0 0 60px 0;
        }
    }
}

@media only screen and (min-width: 550px) {
    .news-list--first-image {
        display: grid;
        grid-template-columns: calc(50% - 8px) calc(50% - 8px);
        grid-template-rows: calc(33.33% - 16px) calc(33.33% - 16px) calc(33.33% - 16px);
        gap: 16px 16px;
        grid-template-areas:
            "item-1 item-2"
            "item-1 item-3"
            "item-4 item-5";

        @media only screen and (min-width: 768px) {
            grid-template-columns: calc(33.33% - 16px) calc(33.33% - 16px) calc(33.33% - 16px);
            grid-template-rows: calc(50% - 24px) 50%;
            gap: 24px 24px;
            grid-template-areas:
                "item-1 item-2 item-3"
                "item-1 item-4 item-5";
        }

        @media all and (-ms-high-contrast: none) {
            display: -ms-grid;
            -ms-grid-columns: calc(50% - 8px) calc(50% - 8px);
            -ms-grid-rows: calc(33.33% - 16px) calc(33.33% - 16px) calc(33.33% - 16px);
            gap: 16px 16px;

            @media only screen and (min-width: 768px) {
                -ms-grid-columns: calc(33.33% - 16px) calc(33.33% - 16px) calc(33.33% - 16px);
                -ms-grid-rows: calc(50% - 24px) 50%;
                gap: 24px 24px;
            }
        }

        .news-list__item {
            &:nth-child(1) {
                grid-area: item-1;
                @media all and (-ms-high-contrast: none) {
                    -ms-grid-row: 1;
                    -ms-grid-row-span: 2;
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 1;

                    @media only screen and (min-width: 768px) {
                        -ms-grid-row: 1;
                        -ms-grid-row-span: 2;
                        -ms-grid-column: 1;
                        -ms-grid-column-span: 1;
                    }
                }

                .news-card {
                    &__image {
                        flex: 1;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                }
            }
            &:nth-child(2) {
                grid-area: item-2;
                @media all and (-ms-high-contrast: none) {
                    -ms-grid-row: 1;
                    -ms-grid-row-span: 1;
                    -ms-grid-column: 2;
                    -ms-grid-column-span: 2;
                    @media only screen and (min-width: 768px) {
                        -ms-grid-row: 1;
                        -ms-grid-row-span: 1;
                        -ms-grid-column: 2;
                        -ms-grid-column-span: 2;
                    }
                }
            }
            &:nth-child(3) {
                grid-area: item-3;
                @media all and (-ms-high-contrast: none) {
                    -ms-grid-row: 2;
                    -ms-grid-row-span: 1;
                    -ms-grid-column: 2;
                    -ms-grid-column-span: 1;
                    @media only screen and (min-width: 768px) {
                        -ms-grid-row: 1;
                        -ms-grid-row-span: 1;
                        -ms-grid-column: 3;
                        -ms-grid-column-span: 3;
                    }
                }
            }
            &:nth-child(4) {
                grid-area: item-4;
                @media all and (-ms-high-contrast: none) {
                    -ms-grid-row: 3;
                    -ms-grid-row-span: 1;
                    -ms-grid-column: 1;
                    -ms-grid-column-span: -1;
                    @media only screen and (min-width: 768px) {
                        -ms-grid-row: 2;
                        -ms-grid-row-span: 1;
                        -ms-grid-column: 2;
                        -ms-grid-column-span: 1;
                    }
                }
            }
            &:nth-child(5) {
                grid-area: item-5;
                @media all and (-ms-high-contrast: none) {
                    -ms-grid-row: 3;
                    -ms-grid-row-span: 1;
                    -ms-grid-column: 2;
                    -ms-grid-column-span: 0;
                    @media only screen and (min-width: 768px) {
                        -ms-grid-row: 2;
                        -ms-grid-row-span: 1;
                        -ms-grid-column: 3;
                        -ms-grid-column-span: 2;
                    }
                }
            }
        }
    }
}

.news-list--classic {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 16px);
    margin: 0 -8px;
    @media only screen and (min-width: 768px) {
        width: calc(100% + 24px);
        margin: 24px -12px 0 -12px;
    }

    .news-list__item {
        width: 100%;
        padding: 0 8px;
        margin: 0 0 16px 0;
        @media only screen and (min-width: 550px) {
            width: 50%;
        }
        @media only screen and (min-width: 768px) {
            width: 33.33%;
            padding: 0 12px;
            margin: 0 0 24px 0;
        }
    }
}

.news-card {
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
    color: #004CAB;

    @media only screen and (max-width: 549px) {
        margin: 0 0 15px 0;

        .news-card__image {
            padding-top: 50%;
        }
    }

    &:hover {
        .news-card__title {
            color: #FFBC03;
        }
        .news-card__more {
            text-decoration: underline;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    &__container {
        padding: 15px 15px 15px 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        @media only screen and (min-width: 991px) {
            padding: 15px 30px 24px 30px;
        }
    }

    &__date {
        font-weight: bold;
        font-size: 12px;
        line-height: 24px;
        color: #83a6c9;
        margin: 0 0 10px 0;

        @media only screen and (min-width: 991px) {
            margin: 0 0 24px 0;
        }

        img {
            margin: 0 5px 0 0;
            position: relative;
            top: 1px;
        }
    }
    &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        color: #004CAB;
        transition: 0.3s;
        margin: 0 0 14px 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @media only screen and (min-width: 991px) {
            margin: 0 0 24px 0;
        }
    }
    &__desc {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #83a6c9;
        margin: 0 0 14px 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    &__more {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #004CAB;
        display: flex;
        align-items: center;

        img {
            width: 12px;
            height: 12px;
            margin: 0 0 0 5px;
        }
    }
}

.content__page-intro {
    background: url(../images/bg-header.jpg) no-repeat top center;
    background-size: cover;
    padding: 60px 0 20px 0;
    position: relative;
    z-index: 3;
    @media only screen and (min-width: 991px) {
        padding: 96px 0 35px 0;
    }
    @media only screen and (min-width: 1200px) {
        padding: 165px 0 35px 0;
    }
}

.page-intro {
    text-align: center;
    padding: 40px 0 0 0;
    @media only screen and (min-width: 768px) {
        text-align: left;
    }
    @media only screen and (min-width: 1200px) {
        padding: 60px 0 0 0;
    }

    h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
        color: #004CAB;
        padding: 0;
        margin: 0 0 10px 0;
        @media only screen and (min-width: 991px) {
            letter-spacing: -1px;
            font-size: 38px;
            line-height: 44px;
        }
        @media only screen and (min-width: 1200px) {
            font-size: 48px;
            line-height: 64px;
        }
    }
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        color: #004CAB;
        margin: 0;
        @media only screen and (min-width: 991px) {
            font-size: 20px;
            line-height: 28px;
        }
        @media only screen and (min-width: 1200px) {
            font-size: 24px;
            line-height: 32px;
        }
    }

    &__date {
        display: flex;
        align-items: center;
        column-gap: 14px;
        @media only screen and (max-width: 767px) {
            justify-content: center;
        }
    }

    &__products {
        display: flex;
        flex-direction: column;

        @media only screen and (min-width: 991px) {
            flex-direction: row;
        }
    }

    &__left {
        flex: 1;
    }

    &__right {
        padding-top: 2rem;

        @media only screen and (min-width: 991px) {
            padding-top: unset;
            margin: auto;
        }
    }
}

.clone-column-table-wrap {
    width: 13.5rem;

    @media only screen and (min-width: 550px) {
        width: 23rem;
    }
}

.compar {
    padding: 0 0 90px 0;
    margin: -45px 0 0 0;
}

.compar-table-wrapper {
    /* max-height: 80rem !important;
    overflow: hidden;*/
    position: relative;
    /*
    &:not(.open) {
        .clone-column-table-wrap {
            height: 80rem !important;
            overflow: hidden;
        }
    }

    .clone-head-table-wrap {
        display: none;
    }

    &.open {
        max-height: 100% !important;

        .clone-head-table-wrap {
            display: block;
        }

        &:before {
            content: none;
            display: none;
        }
    }*/
}

.compar-main-table {
    width: 100%;
    font-size: 1.4rem;
    font-weight: normal;
    color: #000;

    table {
        border-spacing: 0 !important;
        border-collapse: collapse;

        tbody {
            tr {
                &:last-child {
                    td {
                        &:nth-child(2n) {
                            border-bottom: 2px solid #efefef;
                        }
                    }
                }
            }
        }
    }

    &__caption {
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        text-transform: uppercase;
        color: #004CAB;
        padding: 0;
        margin: 0.5rem 0 0 0;
        text-align: left;
        width: 13.5rem;

        @media only screen and (min-width: 550px) {
            width: 220px;
        }
    }

    &__bg-row {
        background: #fafafa;
    }

    tr {
        th {
            padding: 0;
        }

        td {
            position: relative;

            &:first-child {
                width: 13.5rem;

                @media only screen and (min-width: 550px) {
                    width: 245px;
                }
            }

            padding: 11px 13px;
            text-align: center;
        }
    }
}

.compar-main-table-param {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    position: relative;

    &__label {
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        color: #004CAB;
    }

    &__tooltip {
        position: absolute;
        bottom: 100%;
        right: 0.8rem;
        transform: translateX(50%);
        box-shadow: 0 0 4rem rgba(0, 27, 99, 0.11);
        background: #fff;
        margin: 0 0 2rem 0;
        border-radius: 1.1rem;
        padding: 1.5rem;
        z-index: 99;
        width: 100vw;
        max-width: 15rem;
        font-size: 1.4rem;
        display: none;
        text-align: center;

        &:after {
            content: "";
            width: 2rem;
            height: 2rem;
            background: #fff;
            position: absolute;
            bottom: -1rem;
            transform: translateX(50%) rotate(45deg);
            right: 50%;
        }
    }

    &__button {
        width: 18px;
        height: 18px;
        border-radius: 100%;
        background: #dadada;
        position: relative;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
        margin: 0;

        span {
            color: #fff;
            font-size: 12px;
            font-weight: bold;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.compar-main-table-state {
    //position: relative;

    &::before,
    &::after {
        content: "";
        width: 2px;
        height: 100%;
        position: absolute;
        top: 0;
        background: #efefef;
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }
}

.compar-main-table-head {
    position: relative;
    padding: 25px 0 0 0;
    border: 2px solid #efefef;
    border-bottom: none;
    background: #fff;
    text-align: center;
    width: 208px;
    z-index: 1;

    a {
        text-decoration: none;
        transition: 0.3s;

        &:hover {
            text-decoration: underline;
        }
    }

    &__logo {
        display: block;
        width: 100%;
        height: 63px;
        position: relative;
        margin: 0 0 20px 0;

        img {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__name {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: #004CAB;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        min-height: 40px;
        position: relative;
        margin: 0 0 30px 0;

        &::before {
            content: "";
            width: 47px;
            height: 3px;
            background: #FFBC03;
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
        }

        span {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__price {
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        text-transform: uppercase;
        color: #00AC39;
        margin: 0 0 18px 0;
    }

    &__controls {
        margin: 0 0 12px 0;

        .btn {
            width: calc(100% + 4px);
            margin: 0 -2px;
            text-align: center;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0 15px 0;
        margin: 0;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #004CAB;
        text-decoration: none;

        &:hover {
            text-decoration: underline !important;
        }
    }
}

.clone-head-table-wrap {
    max-height: 13rem;
    top: 60px !important;
    z-index: 1 !important;

    .compar-main-table-head__discount {
        display: none;
    }

    .compar-main-table-head__logo {
        height: 4.4rem;

        img {
            max-width: 8rem;
        }
    }

    .compar-main-table-head__name {
        font-size: 12px;
        min-height: 2rem;

        span {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .compar-main-table-head__controls .btn {
        padding: 0.7rem 1rem;
        transition: 0s !important;
    }
}

.btn-buy {
    background: #FFBC03;
    color: #004CAB;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: color 0.3s;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    overflow: hidden;

    @media only screen and (min-width: 991px) {
        font-size: 16px;
        line-height: 20px;
        padding: 15px 10px;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 100%;
        left: 0;
        z-index: -1;
        -webkit-transition: -webkit-transform 0.4s;
        transition: transform 0.4s;
        -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
        transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    }

    &::before {
        background: #086aa7;
    }

    &::after {
        background: #004CAB;
    }

    &:hover {
        color: #fff !important;
        text-decoration: none !important;

        &::before,
        &::after {
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }

        &::after {
            -webkit-transition-delay: 0.175s;
            transition-delay: 0.175s;
        }
    }
}

.content__filters {
    background: #fffcf1;
    padding: 25px 0 60px 0;

    @media only screen and (min-width: 991px) {
        padding: 65px 0 100px 0;
    }
}

.filters {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 14px);
    margin: 0 -7px;
    @media only screen and (min-width: 991px) {
        width: calc(100% + 24px);
        margin: 0 -12px;
    }

    &__item {
        width: 100%;
        padding: 0 7px;
        display: flex;
        flex-direction: column;
        @media only screen and (min-width: 550px) {
            width: 50%;
        }
        @media only screen and (min-width: 768px) {
            width: 33.33%;
        }
        @media only screen and (min-width: 991px) {
            width: 25%;
            padding: 0 12px;
        }
        @media only screen and (min-width: 1200px) {
            width: 20%;
        }
    }
}

.filter-item {
    margin: 0 0 14px 0;
    background: $COLOR_PRIMARY;
    color: $COLOR_PRIMARY_TEXT;
    width: 100%;
    height: 100%;
    padding: 15px 15px 15px 45px;
    position: relative;
    transition: 0.3s;
    cursor: pointer;

    @media only screen and (min-width: 991px) {
        margin: 0 0 24px 0;
    }

    &.checked {
        &::after {
            opacity: 1;
        }
    }

    &:hover {
        background: $COLOR_SECONDARY;
        color: $COLOR_SECONDARY_TEXT;
    }

    &:before {
        content: "";
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        border: 0.1rem solid #dcdcdc;
        background: #dcdcdc;
        border-radius: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 15px;
    }

    &:after {
        content: "";
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        background: #FFBC03;
        border-radius: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 15px;
        opacity: 0;
        transition: 0.3s;
    }

    input {
        display: none;
    }

    &__caption {
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        text-transform: uppercase;
        color: inherit;
    }
}

.content__article {
    padding: 40px 0;
    @media only screen and (min-width: 991px) {
        padding: 55px 0;
    }
}

.content__buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2rem;
}

.article {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #004CAB;

    &--bigger-text {
        @media only screen and (min-width: 991px) {
            font-size: 20px;
            line-height: 32px;

            h2 {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }

    img {
        max-width: 100%;
    }

    a {
        color: inherit;

        &:hover {
            color: $COLOR_SECONDARY;
        }
    }

    blockquote {
        padding: 2rem;
        border-radius: 0.8rem;
        background: url(../images/bg-header.jpg);
        margin: 0 0 3rem 0;
        border-radius: 0;
        line-height: 1.8;
        color: #004CAB;

        @media only screen and (min-width: 991px) {
            font-size: 20px;
            line-height: 32px;
            padding: 2rem 4rem;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            margin: 0 0 12px 0;
            padding: 0 0 0 20px;
            position: relative;
            color: #83a6c9;
            &::before {
                content: "";
                width: 9px;
                height: 9px;
                background: #d4d638;
                position: absolute;
                top: 9px;
                left: 0;
                border-radius: 100%;
            }
        }
    }
}

.content__form {
    background: #e6f2f1;
    padding: 40px 0;
    @media only screen and (min-width: 991px) {
        padding: 80px 0;
    }
}

.form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &__title {
        width: 100%;
        padding: 0 8px 0 0;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        color: #004CAB;
        margin: 0 0 15px 0;
        @media only screen and (min-width: 991px) {
            width: 25%;
        }
    }

    &__field {
        width: 100%;
        @media only screen and (min-width: 580px) {
            width: 40%;
        }
        @media only screen and (min-width: 991px) {
            width: 25%;
        }

        input {
            font-family: $FONT_CONTENT;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: $COLOR_PRIMARY;
            margin: 0 0 8px 0;
            border: 1px solid #fff;
            background: #fff;
            padding: 10px 15px;
            width: 100%;
            @media only screen and (min-width: 768px) {
                font-size: 20px;
                line-height: 25px;
                padding: 10px 25px;
            }

            &::placeholder {
                color: #bababa;
            }
        }
    }

    &__text {
        width: 100%;
        @media only screen and (min-width: 580px) {
            padding: 0 0 0 8px;
            width: 60%;
        }
        @media only screen and (min-width: 991px) {
            width: 50%;
        }
        textarea {
            font-family: $FONT_CONTENT;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: $COLOR_PRIMARY;
            margin: 0 0 8px 0;
            border: 1px solid #fff;
            background: #fff;
            padding: 10px 15px;
            resize: none;
            width: 100%;
            height: calc(100% - 8px);
            @media only screen and (min-width: 768px) {
                padding: 10px 25px;
                font-size: 20px;
                line-height: 25px;
            }
            @media only screen and (max-width: 579px) {
                min-height: 130px;
            }

            &::placeholder {
                color: #bababa;
            }
        }
    }

    &__controls {
        width: 100%;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        @media only screen and (max-width: 767px) {
            margin: 0 auto;
        }
    }
    &__check {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #004CAB;
        position: relative;
        padding: 0 15px 0 27px;

        input {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            margin: 0;
            padding: 0;
        }
    }

    &__btn {
        background: #FFBC03;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: #004CAB;
        padding: 14px 37px;

        @media only screen and (max-width: 550px) {
            padding: 14px 25px;
        }

        &:hover {
            background: darken(#FFBC03, 10%);
        }
    }
}

.content__detail-intro {
    background: url(../images/bg-header.jpg) no-repeat top center;
    background-size: cover;
    padding: 60px 0 35px 0;
    position: relative;
    z-index: 3;
    @media only screen and (min-width: 991px) {
        padding: 96px 0 45px 0;
    }
    @media only screen and (min-width: 1200px) {
        padding: 165px 0 65px 0;
    }
}

.detail-intro {
    text-align: center;
    padding: 60px 0 0 0;
    @media only screen and (min-width: 768px) {
        text-align: left;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    @media only screen and (min-width: 1200px) {
        padding: 110px 0 0 0;
    }

    &__text {
        @media only screen and (min-width: 768px) {
            width: 50%;
        }
    }

    h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
        color: #004CAB;
        padding: 0;
        margin: 0 0 15px 0;
        @media only screen and (min-width: 991px) {
            letter-spacing: -1px;
            font-size: 38px;
            line-height: 44px;
        }
        @media only screen and (min-width: 1200px) {
            font-size: 48px;
            line-height: 64px;
        }
    }
}

.content__video {
    padding: 40px 0;
    @media only screen and (min-width: 991px) {
        padding: 75px 0;
    }
}

.content__params {
    background: #e6f2f1;
    padding: 40px 0;
    @media only screen and (min-width: 991px) {
        padding: 65px 0;
    }

    h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        text-transform: uppercase;
        color: #004CAB;
        margin: 0 0 18px 0;
    }
}

.detail,
.video {
    h2 {
        font-weight: bold;
        color: #004CAB;
        font-size: 28px;
        line-height: 35px;
        margin: 0 0 30px 0;

        @media only screen and (min-width: 991px) {
            font-size: 36px;
            line-height: 45px;
            margin: 0 0 40px 0;
        }
    }
}

.detail {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #004CAB;

    &__image {
        padding: 0 0 30px 0;
        position: relative;
        width: 100%;
        @media only screen and (min-width: 600px) {
            width: 50%;
            padding: 0 30px 0 0;
        }
        @media only screen and (min-width: 991px) {
            padding: 0 40px 0 0;
        }
        @media only screen and (min-width: 1200px) {
            padding: 0 90px 0 0;
        }
    }

    &__info {
        width: 100%;
        @media only screen and (min-width: 600px) {
            width: 50%;
        }
    }

    &__category {
        padding: 25px 0 30px 0;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #004CAB;
        @media only screen and (min-width: 991px) {
            padding: 35px 0 40px 0;
            font-size: 24px;
            line-height: 32px;
        }
        @media only screen and (min-width: 1200px) {
            padding: 35px 0 60px 0;
        }

        img {
            max-width: 45px;
            max-height: 45px;
            margin: 0 30px 0 0;
        }
    }

    &__controls {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media only screen and (min-width: 1200px) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            column-gap: 24px;
        }

        .btn-big {
            font-size: 20px;
            line-height: 28px;
            padding: 15px 25px 15px 30px;

            @media only screen and (min-width: 991px) {
                font-size: 20px;
                line-height: 28px;
                padding: 20px 25px 20px 35px;
            }
        }
    }
    &__prices {
        margin: 20px 0 40px;
        @media only screen and (min-width: 1200px) {
            margin: 0;
            text-align: right;
        }
        .vatless-price {
            color: #888;
        }
        .base-price {
            font-style: normal;
            font-weight: 750;
            font-size: 28px;
            line-height: 35px;
            color: #00AC39;

            @media only screen and (min-width: 1200px) {
                font-size: 32px;
                line-height: 38px;
            }
        }
    }

    &__separator {
        width: 100%;
        height: 2px;
        background: #bfd4e2;
    }

    &__description {
        width: 100%;
        padding: 80px 0 50px 0;

        p {
            margin: 0 0 35px 0;
        }

        table {
            width: 100%;
            margin: 0;
            border-spacing: 0;
            border-collapse: collapse;
            tbody {
                tr {
                    td {
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 20px;
                        color: #004CAB;
                        background: #fafafa;
                        border-bottom: 8px solid #fff;
                        padding: 10px 10px;
                        text-align: center;
                        width: 50%;
                        @media only screen and (min-width: 991px) {
                            padding: 10px 15px;
                            font-size: 20px;
                            line-height: 25px;
                        }
                        &:first-child {
                            border-right: 2px solid #efefef;
                            text-align: left;
                        }

                        button {
                            vertical-align: middle;
                            top: -2px;
                            margin: 0 0 0 5px;
                        }
                    }
                }
            }
        }
    }
}

.params {
    table {
        width: 100%;
        margin: 0;
        border-spacing: 0;
        border-collapse: collapse;
        tbody {
            tr {
                &:nth-child(2n) {
                    td {
                        background: #fafafa;
                    }
                }
                td {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: #004CAB;
                    background: #fff;
                    border-bottom: 8px solid #e6f2f1;
                    padding: 10px 10px;
                    text-align: center;
                    width: 50%;
                    @media only screen and (min-width: 991px) {
                        padding: 10px 15px;
                        font-size: 20px;
                        line-height: 25px;
                    }
                    &:first-child {
                        border-right: 2px solid #efefef;
                        text-align: left;
                    }

                    button {
                        vertical-align: middle;
                        top: -2px;
                        margin: 0 0 0 5px;
                    }
                }
            }
        }
    }
}

.video-container {
    .video {
        position: relative;
        padding-bottom: 51.25%;
        padding-top: 25px;
        height: 0;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.detail-gallery {
    position: relative;
    width: 100%;
    padding-top: 100%;
    background: #fffef2;
    margin: 0;
    z-index: 3;
    @media only screen and (min-width: 768px) {
        margin: -25% 0 0 0;
    }
    @media only screen and (min-width: 991px) {
        margin: -30% 0 0 0;
    }
    @media only screen and (min-width: 1200px) {
        margin: -40% 0 0 0;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-height: 100%;
        max-width: 100%;
        transform: translate(-50%, -50%);
    }
}

.content__account {
    padding: 40px 0;
    @media only screen and (min-width: 991px) {
        padding: 45px 0 100px 0;
    }
}

.account {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;

    @media only screen and (min-width: 550px) {
        &::before {
            content: "";
            width: 2px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 215px;
            background: #e6f2f1;
        }
    }

    &__sidebar {
        width: 100%;
        @media only screen and (min-width: 550px) {
            width: 215px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin: 0;
                padding: 0;

                &.selected {
                    a {
                        background: #e6f2f1;
                    }
                }

                &:last-child {
                    a {
                        color: #ff0000;
                    }
                }

                a {
                    display: flex;
                    align-items: center;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 22px;
                    color: #004CAB;
                    text-decoration: none;
                    min-height: 48px;
                    padding: 10px 10px 10px 15px;
                    img {
                        margin: 0 15px 0 0;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &__content {
        width: 100%;
        @media only screen and (min-width: 550px) {
            width: calc(100% - 215px);
        }
    }
    &__title {
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        background: #e6f2f1;
        color: #004CAB;
        margin: 0;
        position: relative;
        padding: 11px 15px;

        @media only screen and (min-width: 768px) {
            font-size: 24px;
            line-height: 30px;
            padding: 9px 24px;
        }

        &--w-export {
            padding: 11px 80px 11px 15px;
            @media only screen and (min-width: 768px) {
                padding: 9px 80px 9px 24px;
            }
        }
    }
}

.account-container {
    padding: 20px 0;
    @media only screen and (min-width: 550px) {
        padding: 20px 0 20px 15px;
    }
    @media only screen and (min-width: 991px) {
        padding: 20px 0 20px 24px;
    }
}

.form-field {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 10px 0;

    &__label {
        margin: 0;
        width: 22rem;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #004CAB;
        display: block;
        padding: 0 0 5px 0;
        @media only screen and (min-width: 768px) {
            padding: 10px 0 0 0;
            font-size: 20px;
            line-height: 25px;
        }

        &.w-100 {
            padding: 0 0 1.1rem 0;
            width: 100%;
        }

        a {
            color: inherit;

            &:hover {
                color: #FFBC03;
            }
        }
    }

    &__control {
        width: calc(100% - 22rem);
        &.w-100 {
            width: 100%;
        }
        textarea {
            margin: 0;
            outline: none !important;
            border-radius: 0;
            resize: none;
            min-height: 20rem;
            max-width: 100%;
            font-family: $FONT_CONTENT;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: $COLOR_PRIMARY;
            margin: 0 0 8px 0;
            border: 1px solid #efefef;
            background: #fff;
            padding: 10px 15px;
            width: 100%;
            @media only screen and (min-width: 768px) {
                font-size: 20px;
                line-height: 25px;
                padding: 10px 25px;
            }

            &::placeholder {
                font-style: italic;
            }

            &:focus {
                background: #fff;
                box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
                border-color: #004CAB;
            }
        }

        input {
            margin: 0;
            outline: none !important;
            border-radius: 0;
            resize: none;
            max-width: 100%;
            font-family: $FONT_CONTENT;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: $COLOR_PRIMARY;
            margin: 0 0 8px 0;
            border: 1px solid #efefef;
            background: #fff;
            padding: 10px 15px;
            width: 100%;
            @media only screen and (min-width: 768px) {
                font-size: 20px;
                line-height: 25px;
                padding: 10px 25px;
            }

            &::placeholder {
                font-style: italic;
            }

            &:focus {
                background: #fff;
                box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
                border-color: #004CAB;
            }

            &.radius-bottom {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        &--select {
            position: relative;

            &:before {
                content: "";
                width: 14px;
                height: 14px;
                background: url(../images/arrow-down.svg) no-repeat center center;
                background-size: contain;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 12px;
            }

            select {
                appearance: none;
            }
        }

        select {
            padding: 10px 15px;
            font-size: 16px;
            line-height: 20px;
            font-weight: normal;
            font-family: $FONT_CONTENT;
            border: 1px solid #efefef;
            background: #fff;
            color: $COLOR_PRIMARY;
            margin: 0;
            outline: none !important;
            border-radius: 0;
            resize: none;
            width: 100%;
            max-width: 100%;
            @media only screen and (min-width: 768px) {
                font-size: 20px;
                line-height: 25px;
                padding: 10px 25px;
            }

            &::placeholder {
                font-style: italic;
            }

            &:focus {
                background: #fff;
                box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
                border-color: #004CAB;
            }
        }
    }

    &__checkbox {
        margin: 0.8rem 0;
        display: block;
        color: #000;
        font-weight: 500;
        font-size: 1.5rem;
        position: relative;
        padding: 0 0 0 2.5rem;
        cursor: pointer;
        min-height: 1.6rem;
        line-height: 1.6rem;
        outline: none;
        width: 100%;

        input {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }
        input:checked ~ .form-field__indicator {
            background-color: $COLOR_PRIMARY;
            border-color: $COLOR_PRIMARY;
        }
        input:checked ~ .form-field__indicator {
            background-color: $COLOR_PRIMARY;
            border-color: $COLOR_PRIMARY;
        }
        input:checked ~ .form-field__indicator {
            background: $COLOR_PRIMARY;
        }
        input:checked ~ .form-field__indicator:after {
            display: block;
        }
        input:checked ~ .form-field__label {
            //color:$COLOR_PRIMARY;
        }

        .form-field__label {
            padding: 0 !important;
            width: 100%;
            text-align: left !important;
            font-size: 16px !important;
            font-weight: normal !important;
        }

        .form-field__indicator {
            top: 5px;
            border: 0.1rem solid #c5c5c5;
            background-color: #fff;
            border-radius: 0.4rem;
            position: absolute;
            left: 0;
            height: 1.4rem;
            width: 1.4rem;
            background: #fff;
            &::after {
                content: "";
                position: absolute;
                display: none;
                left: 3px;
                top: 0px;
                width: 6px;
                height: 9px;
                border: solid #fff;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }
    }

    .btn {
        padding: 1.5rem 2rem;
        min-width: 16rem;
        text-align: center;
        justify-content: center;
    }
}

.form-info {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin: -0.8rem 0 2.5rem 0;
    font-size: 1.4rem;
    color: #004CAB;
    a {
        color: inherit;
        text-decoration: underline;

        &:hover {
            color: #FFBC03;
        }
    }
}

.content__auth {
    padding: 40px 0;
    @media only screen and (min-width: 991px) {
        padding: 65px 0;
    }
}

.login-data {
    margin: 0 auto;
    width: 100%;
    max-width: 36rem;
}

.row-link {
    cursor: pointer;
}

.orders-grid {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    thead {
        @media only screen and (max-width: 767px) {
            display: none;
        }
        tr {
            th {
                text-align: center;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #004CAB;
                padding: 5px 0 15px 0;
                @media only screen and (min-width: 991px) {
                    font-size: 20px;
                    line-height: 25px;
                }
            }
        }
    }
    tbody {
        tr {
            &.row-link {
                &:hover {
                    td {
                        background: #f1f1f1;
                    }
                }
            }
            &:nth-child(2n) {
                td {
                    background: transparent;
                }
            }
            td {
                background: #fafafa;
                text-align: center;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #004CAB;
                padding: 10px 5px;
                @media only screen and (max-width: 767px) {
                    display: block;
                    padding: 3px 5px;
                    &:first-child {
                        padding: 12px 5px 3px 5px;
                    }
                    &:last-child {
                        padding: 3px 5px 12px 5px;
                    }
                }
                @media only screen and (min-width: 991px) {
                    font-size: 20px;
                    line-height: 25px;
                }
            }
        }
    }
    tfoot {
        tr {
            td {
                border-top: 2px solid #f1f1f1;
                text-align: center;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                color: #004CAB;
                padding: 15px 15px 15px 15px;
                @media only screen and (min-width: 991px) {
                    font-size: 20px;
                    line-height: 25px;
                }
            }
        }
    }
}

.password-change-data,
.settings-data {
    width: 100%;
    max-width: 490px;
    padding: 10px 0 0 0;

    .form-field, .checkbox-firm_type {
        .form-field__label {
            padding: 0 0 5px 0;
            @media only screen and (min-width: 768px) {
                padding: 10px 40px 0 0;
                text-align: right;
            }
        }
    }

    .btn-primary {
        margin: 10px 0 0 0;
    }

    &--wide {
        max-width: 100%;
    }
}

.settings-data {
    &.disabled-inputs {
        #form-edit {
            display: block;
        }
        #form-submit {
            display: none;
        }
        .form-field__inp {
            pointer-events: none;
            appearance: none;
        }
    }
    &:not(.disabled-inputs) {
        #form-edit {
            display: none;
        }
        #form-submit {
            display: block;
        }
    }
}

.admin-show {
    background: #fafafa;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #004CAB;
    padding: 10px 15px;
    text-align: left;

    dt {
        font-weight: bold;
        margin-bottom: .5rem;
    }

    dd {
        margin-left: 0;
        margin-bottom: 2rem;
    }
}

.order-grid {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    &--user-data {
        margin: 40px 0 0 0;
    }

    thead {
        @media only screen and (max-width: 767px) {
            display: none;
        }
        tr {
            th {
                text-align: left;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                color: #004CAB;
                padding: 5px 15px 15px 15px;
                @media only screen and (min-width: 991px) {
                    font-size: 20px;
                    line-height: 25px;
                }
            }
        }
    }
    tbody {
        tr {
            &:nth-child(2n) {
                td {
                    background: transparent;
                }
            }
            td {
                vertical-align: top;
                background: #fafafa;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: #004CAB;
                padding: 10px 15px;
                text-align: left;
                @media only screen and (max-width: 767px) {
                    text-align: center;
                    display: block;
                    padding: 3px 5px;
                    &:first-child {
                        padding: 12px 5px 3px 5px;
                    }
                    &:last-child {
                        padding: 3px 5px 12px 5px;
                    }
                }
                @media only screen and (min-width: 991px) {
                    font-size: 20px;
                    line-height: 25px;
                }

                .form-field {
                    &__label {
                        width: unset;
                        padding-top: 15px;
                        padding-right: 10px;
                        font-size: 16px;
                        line-height: 20px;
                        @media only screen and (min-width: 991px) {
                            font-size: 20px;
                            line-height: 25px;
                            padding-top: 10px;
                        }
                    }
                    &__control {
                        width: unset;
                        flex: 1;

                        select {
                            font-size: 16px;
                            line-height: 20px;
                            @media only screen and (min-width: 991px) {
                                font-size: 20px;
                                line-height: 25px;
                            }
                        }
                    }
                }

                h2 {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 20px;
                    color: #004CAB;
                    padding: 5px 0 15px 0;
                    margin: 0;
                    @media only screen and (min-width: 991px) {
                        font-size: 20px;
                        line-height: 25px;
                        padding: 10px 0 15px 0;
                    }

                    &.mobile-caption {
                        display: none;
                        @media only screen and (max-width: 767px) {
                            text-align: center;
                            display: block;
                        }
                    }
                }

                p {
                    margin: 0;
                }

                a {
                    color: inherit;

                    &:hover {
                        color: #FFBC03;
                    }
                }
            }
        }
    }
}

.account__filters {
    margin: 0 0 24px 0;
    @media only screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
}

.filters-group {
    .form-field {
        align-items: center;

        &--sorting {
            max-width: 100%;
            @media only screen and (min-width: 768px) {
                max-width: 260px;
            }
            @media only screen and (min-width: 991px) {
                max-width: 300px;
            }
            @media only screen and (min-width: 1200px) {
                max-width: 400px;
            }
        }

        &--date {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                bottom: 23px;
                right: 1.2rem;
                width: 18px;
                height: 18px;
                background: url(../images/calendar.svg) no-repeat center center;
                background-size: contain;
                pointer-events: none;
                filter: invert(41%) sepia(0%) saturate(0%) hue-rotate(254deg) brightness(95%) contrast(92%);
            }

            .form-field__label {
                min-width: 83px;
                @media only screen and (min-width: 768px) {
                    min-width: 100px;
                }
            }
        }

        &__label {
            width: auto;
            padding: 0 10px 0 0;
        }

        &__control {
            width: auto;
            flex: 1;
        }
    }
}

.filters__search {
    @media only screen and (max-width: 767px) {
        width: 100%;
        //display: flex;
    }
}

.search-form {
    display: flex;
    margin: 0 0 10px 0;
    max-width: 100%;
    width: 100%;
    @media only screen and (min-width: 768px) {
        max-width: 240px;
        width: unset;
    }
    @media only screen and (min-width: 991px) {
        max-width: 300px;
    }
    @media only screen and (min-width: 1200px) {
        max-width: 400px;
    }

    input {
        padding: 10px 15px;
        font-size: 16px;
        line-height: 20px;
        font-weight: normal;
        font-family: $FONT_CONTENT;
        border: 1px solid #efefef;
        background: #fff;
        color: #004CAB;
        margin: 0;
        outline: none !important;
        border-radius: 0;
        resize: none;
        min-width: 1px;
        flex: 1;

        @media only screen and (min-width: 768px) {
            font-size: 20px;
            line-height: 25px;
            padding: 10px 25px;
        }
    }
    .btn-search {
        display: inline-block;
        background: #00AC39;
        padding: 12px 17px;
        position: relative;

        &:hover {
            background: darken(#00AC39, 10%);
        }
    }
}

.btn-export {
    display: inline-block;
    background: #FFBC03;
    padding: 12px 17px;
    position: relative;

    &:hover {
        background: darken(#FFBC03, 10%);
    }
}

.account__title--w-export {
    .btn-export {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.btn-tooltip {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background: #004CAB;
    display: inline-block;
    padding: 5px 10px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 999;

    &::before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0%);
        border-top: 5px solid #004CAB;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
    }
}

a.btn {
    padding: 14px 17px;
    img {
        display: block;
    }
}

.btn {
    position: relative;
    img {
        pointer-events: none;
    }
    &:hover {
        .btn-tooltip {
            transform: translate(-50%, calc(-100% - 5px));
            opacity: 1;
            visibility: visible;
        }
    }

    &.compar-main-table-param__button {
        .btn-tooltip {
            min-width: 160px;
        }
        &:hover {
            .btn-tooltip {
                min-width: 160px;
                transform: translate(-50%, calc(-100% - 12px));
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.PopupBody {
    display: none;
    width: 100%;

    &.fancybox-content {
        padding: 4rem 3rem 2rem 3rem;
    }
}

.popup-window {
    &__content {
        font-size: 1.8rem;
        text-align: center;

        @media only screen and (min-width: 550px) {
            font-size: 2rem;
        }
    }
    &__btn {
        position: relative;
        font-weight: normal;
        display: inline-block;
        border-radius: 0rem;
        border: none;
        color: $COLOR_SECONDARY_TEXT;
        background: $COLOR_SECONDARY;
        font-size: 1.2rem;
        font-family: $FONT_CONTENT;
        line-height: 1.1;
        letter-spacing: 0rem;
        text-decoration: none !important;
        white-space: nowrap;
        transition: 0.3s;
        outline: none !important;
        cursor: pointer;
        padding: 1.4rem 2.5rem;
        font-weight: bold;
        @media only screen and (min-width: 550px) {
            font-size: 1.6rem;
            padding: 1.5rem 4rem;
        }

        &--back {
            background: #cfcfcf;
            color: #004CAB;
            &:hover {
                background: #004CAB;
                color: #FFBC03;
            }
        }

        &:hover {
            background: #004CAB;
            color: #FFBC03;
        }
    }
}

.account-settings-list {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 24px);
    margin: 0 -12px;

    &__item {
        padding: 0 12px;
        margin: 0 0 15px 0;
        width: 100%;
        @media only screen and (min-width: 550px) {
            width: 50%;
        }
        @media only screen and (min-width: 768px) {
            width: 33.33%;
        }
        @media only screen and (min-width: 991px) {
            width: 25%;
        }

        a {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #004CAB;
            transition: 0.3s;
            text-decoration: none;
            text-align: center;
            border: 1px solid #004CAB;
            width: 100%;
            height: 100%;
            display: flex;
            padding: 10px;
            align-items: center;
            justify-content: center;

            &:hover {
                background: #004CAB;
                color: #FFBC03;
            }

            @media only screen and (min-width: 991px) {
                padding: 15px;
                font-size: 20px;
                line-height: 25px;
            }
        }
    }
}

.select2-container--default,
.select2-selection--multiple {
    border-color: #efefef !important;
    border-radius: 0 !important;
}

.select2-container--default.select2-container--focus {
    border: solid #004CAB 1px !important;
}

.select2,
.select2-container {
    font-family: $FONT_CONTENT;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #004CAB;
}

.logo-preview {
    max-width: 100%;

    &--icon {
        max-width: 160px;
        .logo-preview__box {
            padding: 0;
            height: 120px;
        }
    }

    &__box {
        position: relative;
        width: 100%;
        padding-top: 55%;
        border: 0.1rem solid #f1f1f1;
        background: #fff;
        border-radius: 0.5rem;
        margin: 0 0 2rem 0;
        cursor: pointer;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: calc(100% - 2rem);
            max-height: calc(100% - 2rem);
        }
    }

    &__info {
        padding: 2rem 0 0 0;
        margin: 0 0 2rem 0;
        font-size: 14px;
        color: #999;
    }
}

.btn-upload {
    display: inline-block;
    background: #004CAB;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    padding: 12px 22px;

    &:hover {
        background: #FFBC03;
        color: #004CAB;
    }
}

.settings-data {
    .form-field {
        @media only screen and (max-width: 767px) {
            &__label {
                width: 100%;
            }
            &__control {
                width: 100%;
            }
        }
    }
}

.form-editor {
    width: 100%;
    margin: 0 0 20px 0;
}

.form-field--link {
    font-family: $FONT_CONTENT;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #004CAB;
    justify-content: flex-end;
    padding: 0 0 15px 0;

    a {
        color: inherit;

        &:hover {
            color: #FFBC03;
        }
    }
}

.form-field--onoff {
    .form-field__label {
    }
    .form-field__edit {
        .onoff {
            margin: 6px 0 0 0;
        }
    }
}

.onoff {
    display: inline-block;
    vertical-align: middle;
    zoom: 1;
    position: relative;
    cursor: pointer;
    width: 6.5rem;
    height: 3rem;
    line-height: 3rem;
    font-size: 1.4rem;
    font-family: $FONT_CONTENT;

    input:checked + label {
        background: $COLOR_PRIMARY;
        text-indent: 8px;
    }
    input:checked + label:after {
        content: "ANO";
        color: #fff;
    }
    input:checked + label:before {
        left: 37px;
    }

    label {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        background: $COLOR_PRIMARY;
        border-radius: 3rem;
        font-weight: normal;
        color: #fff;
        transition: background 0.3s, text-indent 0.3s;
        text-indent: 3.5rem;

        &:after {
            content: "NE";
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            font-size: 12px;
            color: #fff;
            z-index: 1;
        }

        &:before {
            content: "";
            width: 2.4rem;
            height: 2.4rem;
            border-radius: 100%;
            background: #fff;
            position: absolute;
            z-index: 2;
            top: 3px;
            left: 3px;
            display: block;
            transition: left 0.3s;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
        }
    }
}

.form-subtitle {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #004CAB;
    margin: 20px 0 15px 0;
    position: relative;
    padding: 0 0 5px 0;
    border-bottom: 2px solid #e6f2f1;
    width: 100%;
}

.content__cart-intro {
    background: url(../images/bg-header.jpg) no-repeat top center;
    background-size: cover;
    padding: 60px 0 25px 0;
    position: relative;
    z-index: 3;
    @media only screen and (min-width: 991px) {
        padding: 96px 0 10px 0;
    }
    @media only screen and (min-width: 1200px) {
        padding: 165px 0 70px 0;
    }
}

.faq-list {
    &__item {
        margin: 0 0 1rem 0;

        &.open {
            .faq-card__header {
                &:before {
                    transform: translateY(-60%) rotate(180deg);
                }
            }
        }
    }
}

.faq-card {
    border: 0.2rem solid #f1f1f1;
    transition: 0.3s;
    border-radius: 0.4rem;

    &:hover {
        border-color: $COLOR_PRIMARY;

        &.open {
            border-color: #f1f1f1;
        }
    }

    &__header {
        background: #f1f1f1;
        padding: 0 4.5rem 0 1.5rem;
        position: relative;
        cursor: pointer;
        border: none;
        margin: 0;
        outline: none !important;
        border-radius: 0;
        display: flex;
        width: 100%;
        text-align: left;
        height: 5.6rem;
        align-items: center;
        transition: 0.3s;
        @media only screen and (min-width: 768px) {
            padding: 0 6rem 0 2.5rem;
        }

        &:hover {
            color: $COLOR_PRIMARY;
        }

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            right: 1.5rem;
            transform: translateY(-60%);
            border-top: 8px solid $COLOR_PRIMARY;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            @media only screen and (min-width: 1200px) {
                right: 2.5;
            }
        }
    }

    &__title {
        margin: 0 !important;
        padding: 0;
        font-size: 1.8rem !important;
        font-weight: 600;
        line-height: 1.2;
        color: #004CAB !important;
    }

    &__texts {
        padding: 1.5rem;
        background: #fff;
        font-size: 16px;
        line-height: 24px;
        color: #004CAB;
        @media only screen and (min-width: 768px) {
            padding: 2.5rem;
        }

        p {
            margin: 0 0 3rem 0;
        }

        img {
            max-width: 100%;
        }

        a {
            color: inherit;
        }

        & > *:last-child {
            margin: 0;
        }
    }
}

.account-create {
    padding: 50px 0 0 0;
    text-align: center;
    font-size: 16px;
    color: #086aa7;
    line-height: 24px;
    margin: 0 auto;
    width: 100%;
    max-width: 36rem;

    h4 {
        margin: 0 0 15px 0;
        font-size: 24px;
        color: #086aa7;
        line-height: 30px;
    }
    p {
        margin: 0 0 25px 0;
    }
}

.auth-flex {
    @media only screen and (min-width: 991px) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .login-data {
            //margin: 0;
        }
    }
}

.auth-done {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media only screen and (min-width: 1200px) {
        padding: 60px;
    }

    &__icon {
        margin: 0 0 25px 0;


        img {
            width: 70px;
            height: 70px;
            filter: brightness(0) saturate(100%) invert(82%) sepia(72%) saturate(451%) hue-rotate(21deg) brightness(86%)
                contrast(95%);

            @media only screen and (min-width: 991px) {
                width: 110px;
                height: 110px;
            }

        }
        &.error {
            img {
                filter: invert(44%) sepia(98%) saturate(6170%) hue-rotate(349deg) brightness(86%) contrast(139%);
            }
        }
    }

    &__title {
        font-weight: 600;
        font-size: 30px;
        line-height: 1.2;
        padding: 0;
        margin: 0 0 25px 0;
        color: #00AC39;
        &.error {
            color: #ff0000;
        }
        @media only screen and (min-width: 991px) {
            font-size: 24px;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.6;
        margin: 0 auto 25px auto;
        width: 100%;
        max-width: 560px;
        color: #004CAB;
    }

    &__controls {
    }
}

.popup-wrapper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100vh;

    &.visible {
        display: block;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 90;
    }

    &__window {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 91;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__container {
        width: 100%;
        max-width: calc(100% - 2rem);

        @media only screen and (min-width: 768px) {
            max-width: calc(100% - 4rem);
        }
    }
}

#popup-branches, #popup-catalog {
    .popup-wrapper__container {
        max-width: calc(100% - 2rem);

        @media only screen and (min-width: 768px) {
            max-width: 76rem;
        }
    }
}

.popup-box {
    position: relative;
    pointer-events: all;
    background: #fff;
    max-height: calc(100vh - 2rem);
    overflow-y: auto;
    padding: 4rem 2rem 2rem 2rem;
    border-radius: 2rem;

    @media only screen and (min-width: 768px) {
        padding: 4rem;
        border-radius: 4rem;
    }

    &__close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        opacity: 1;

        img {
            width: 2rem;
        }
    }
}

.popup-form {
    &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        color: #004CAB;
        margin: 0 0 35px 0;
        padding: 0;
        text-align: center;
        @media only screen and (min-width: 550px) {
            font-size: 36px;
            line-height: 45px;
        }
    }

    .text-wrapper {
        padding: 0;
        margin: 0;

        hr {
            margin: 0;
        }
    }

    &__table {
        display: flex;
        justify-content: center;
        gap: 2rem;
        flex-wrap: wrap;
    }

    &__row {
        flex-basis: 100%;
        @media only screen and (min-width: 550px) {
            flex-basis: calc(50% - 2rem);
        }

        h3 {
            box-sizing: border-box;
            margin: 25px 0px 8px;
            line-height: 22px;
            font-size: 16px;
            -webkit-font-smoothing: antialiased;
            text-transform: uppercase;
            color: rgb(51, 51, 51);
            background-color: rgb(255, 255, 255);
            padding: 0px !important;
            border: none !important;
            font-weight: bold;
        }
        &-text {
            box-sizing: border-box;
            margin: 0px;
            padding: 0px;
            line-height: 21px;
            color: rgb(51, 51, 51);
            font-size: 13px;

            strong {
                font-weight: bold;
                line-height: 21px;
                color: rgb(51, 51, 51);
                font-size: 13px;
                box-sizing: border-box;
                margin: 0px;
                padding: 0px;
            }
        }
    }
}

.tooltip-parent {
    position: absolute;
    right: 0;
    top: -6px;
    .btn-tooltip {
        min-width: 160px;
    }
    &:hover {
        .btn-tooltip {
            min-width: 160px;
            transform: translate(-50%, calc(-100% - 12px));
            opacity: 1;
            visibility: visible;
        }
    }

    @media only screen and (min-width: 550px) {
        right: 40%;
    }

    img {
        max-width: 2rem;
        filter: invert(17%) sepia(86%) saturate(2550%) hue-rotate(186deg) brightness(60%) contrast(101%);
    }
}

.export-pdf {
    body {font-family: sans-serif;
        font-size: 10pt;
    }
    p {	margin: 0pt; }
    table.items {
        border: 0.1mm solid #000000;
    }
    td { vertical-align: top; }
    .items td {
        border-left: 0.1mm solid #000000;
        border-right: 0.1mm solid #000000;
    }
    table thead td { background-color: #EEEEEE;
        text-align: center;
        border: 0.1mm solid #000000;
        font-variant: small-caps;
    }
    .items td.blanktotal {
        background-color: #EEEEEE;
        border: 0.1mm solid #000000;
        background-color: #FFFFFF;
        border: 0mm none #000000;
        border-top: 0.1mm solid #000000;
        border-right: 0.1mm solid #000000;
    }
    .items td.totals {
        text-align: right;
        border: 0.1mm solid #000000;
    }
    .items td.cost {
        text-align: center;
    }
}

.btn-catalog-modal {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

.checkbox-firm_type {
    @extend .form-field;
    display: flex;
    width: 100%;
    align-items: center;

    .form-field__checkbox {
        flex: 1;
    }
}

.collection-wrapper {
    flex: 1;
    &__buttons {
        width: 100%;
        padding-bottom: 1rem;
        position: relative;

        &:not(:last-child) {
            border-bottom: 1px solid #004CAB;
        }

        padding-top: 2rem;

        &-item {
            display: flex;

            .form-field__label {
                width: 10rem;
            }

            .form-field__control {
                flex: 1;
                margin-right: 3rem;
            }
        }
    }

    &__parent {
        padding-bottom: 7rem;
        position: relative;

        .btn-center {
            bottom: 0;
            left: 50%;
            position: absolute;
            transform: translate(-50%);
            margin: 0 0 2rem 0;
        }

        & > .form-field__label {
            padding-top: 2rem !important;
        }
    }

    &__remove {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        padding-bottom: 1rem;

        img {
            transform: scale(1.5);
        }
    }
}

.text-wrapper {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #004CAB;
    width: 100%;

    h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        color: #004CAB;
        margin: 0 0 20px 0;
        padding: 0;
        text-align: center;
        @media only screen and (min-width: 550px) {
            font-size: 36px;
            line-height: 45px;
        }
    }

    hr {
        border-top: 1px solid #FFBC03;
        margin: 0 0 20px 0;
    }

    ul {
        margin: 0 0 20px 0;
        padding: 0;
        list-style: none;
        li {
            margin: 0 0 12px 0;
            padding: 0 0 0 20px;
            position: relative;
            font-size: 16px;
            line-height: 24px;
            color: #83a6c9;
            &::before {
                content: "";
                width: 9px;
                height: 9px;
                background: #d4d638;
                position: absolute;
                top: 7px;
                left: 0;
                border-radius: 100%;
            }
        }

        a {
            color: #83a6c9;

            &:hover {
                color: #FFBC03;
            }
        }
    }

    a {
        color: #004CAB;

        &:hover {
            color: #FFBC03;
        }
    }
}
