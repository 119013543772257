$font: "Avenir";

$primaryBG: #004CAB;
$primaryFG: #fff;

$secondaryBG: #FFBC03;
$secondaryFG: #004CAB;

$defaultText: #4e4e4e;

$borderColor: #e0e0e0;

$primaryFilter: brightness(0) saturate(100%) invert(17%) sepia(64%) saturate(4003%) hue-rotate(188deg) brightness(88%)
    contrast(102%); //#004CAB;
$disabledFilter: brightness(0) saturate(100%) invert(91%) sepia(25%) saturate(2994%) hue-rotate(324deg) brightness(116%)
    contrast(99%); //#FFBC03

@font-face {
    font-family: 'Avenir';
    src: url('../fonts/Avenir/AvenirNextLTPro-MediumCn.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

.shopping-secure {
    //background: #f1f1f1;
}

.shopping-wizard {
    background: transparent;

    &__step {
        .shopping-wizard__caption {
            color: #d7d7d7;
        }

        img {
            filter: $disabledFilter;
        }

        &:hover {
            img {
                filter: $primaryFilter;
            }
            .shopping-wizard__caption {
                color: $primaryBG;
            }
        }

        &.disabled {
            pointer-events: none;
        }

        &.done {
            img {
                filter: $primaryFilter;
            }
            .shopping-wizard__caption {
                color: $primaryBG;
            }
        }
    }

    &__separator {
        img {
            filter: $primaryFilter;
        }

        &.disabled {
            img {
                filter: $disabledFilter;
            }
        }
    }
}

.cart {
    thead {
        tr {
            th {
                border-bottom: 3px solid $primaryBG;
                color: $defaultText;
            }
        }
    }

    tbody {
        tr {
            @media only screen and (max-width: 767px) {
                border-bottom: 2px solid $borderColor;
            }

            td {
                @media only screen and (min-width: 768px) {
                    border-bottom: 2px solid $borderColor;
                }
            }
        }
    }
    &__item {
        color: $defaultText;
    }

    a.cart__item {
        &:hover {
            color: $primaryBG;
        }
    }

    &__price {
        strong {
            color: $primaryBG;
        }
    }

    &__quantity {
        background: #f1f1f1;
        border-radius: 0;
    }
    &__quantity-field {
        border: none;
        border-radius: 0;
        color: $defaultText;
    }

    &__remove {
        border: none;
        background: transparent;
        border-radius: 0;
    }
}

.recap {
    thead {
        tr {
            th {
                color: $defaultText;
                border-bottom: 3px solid $primaryBG;
            }
        }
    }

    tbody {
        tr {
            td {
                color: $defaultText;
            }
        }
    }

    &__price {
        strong {
            color: $primaryBG;
        }
    }
}

.coupon {
    &__field {
        border: none;
        color: $defaultText;
        background: #f1f1f1;
        border-radius: 0;
    }

    &__submit {
        border-radius: 0;
        border: none;
        background: transparent;
        color: $primaryBG;

        &:hover {
            color: $secondaryBG;
        }
    }
}

.shopping-controls {
    &__back {
        background: #f1f1f1;
        color: $defaultText;
        border-radius: 0;

        &:hover {
            background: #ddd;
            color: $defaultText;
        }

        img {
            filter: invert(31%) sepia(0%) saturate(1%) hue-rotate(28deg) brightness(92%) contrast(91%); //#4e4e4e
        }
    }

    &__next {
        background: $secondaryBG;
        color: $secondaryFG;
        border-radius: 0;

        &:hover {
            background: $primaryBG;
            color: $primaryFG;
            img {
                filter: brightness(0) saturate(100%) invert(97%) sepia(0%) saturate(7483%) hue-rotate(313deg)
                    brightness(104%) contrast(104%); //#fff
            }
        }

        img {
            filter: $primaryFilter;
        }
    }
}

.shopping-methods {
    &__title {
        border-bottom: 3px solid $primaryBG;
        color: $defaultText;
    }
}

.method-item {
    border-bottom: 2px solid $borderColor;

    &__radio {
        color: $defaultText;

        input:checked ~ .method-item__indicator {
            background-color: $primaryBG;
            border-color: $primaryBG;
        }
        input:checked ~ .method-item__indicator {
            background-color: $primaryBG;
            border-color: $primaryBG;
        }
        input:checked ~ .method-item__indicator {
            background: $primaryBG;
        }

        &:hover {
            .method-item__label {
                color: $primaryBG;
            }
            .method-item__indicator {
                border-color: $primaryBG;
            }
        }

        .method-item__indicator {
            border-radius: 0;
            border: 1px solid #c5c5c5;
            background: #fff;

            &::after {
                border: solid #fff;
            }
        }
    }

    &__label {
        color: $defaultText;
    }

    &__price {
        color: $primaryBG;
    }
}

.shopping-secure {
    &__title {
        span {
            color: $defaultText;
        }
    }
}

.shopping-login {
    &__button {
        border: 2px solid #f1f1f1;
        background: transparent;
        color: $defaultText;
        border-radius: 0;

        &:hover {
            border-color: $defaultText;
        }

        &.selected {
            color: $defaultText;
            border-color: $primaryBG;
            background: transparent;
        }
    }
}

.shopping-form {
    &__title {
        border-bottom: 3px solid $primaryBG;
        color: $defaultText;
    }
}

.form-submit {
    &__btn {
        background: $primaryBG;
        color: $primaryFG;
        border-radius: 0;

        &:hover {
            background: $secondaryBG;
            color: $secondaryFG;
        }

        img {
            filter: invert(97%) sepia(0%) saturate(7483%) hue-rotate(313deg) brightness(104%) contrast(104%); //#fff
        }
    }
}

.form-field {
    & > img {
        filter: invert(41%) sepia(0%) saturate(0%) hue-rotate(254deg) brightness(95%) contrast(92%);
    }

    & > input,
    & > textarea {
        border-radius: 0;
        border: 2px solid #f1f1f1;
        color: $defaultText;

        &:focus {
            border-color: $primaryBG;
            box-shadow: 0 0 10px rgba($primaryBG, 30%);
        }
    }

    &.error {
        & > img {
            filter: brightness(0) saturate(100%) invert(24%) sepia(98%) saturate(7492%) hue-rotate(337deg)
                brightness(100%) contrast(107%);
        }

        & > input {
            border-color: #ff004c;
            color: #ff004c;

            &::placeholder {
                color: #ff004c;
            }
        }
    }

    .error-label {
        color: #fff;
        background: #ff004c;
        border-radius: 0;

        &:before {
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #ff004c;
        }
    }

    &__checkbox {
        color: $defaultText;

        input:checked ~ .form-field__indicator {
            background-color: $primaryBG;
            border-color: $primaryBG;
        }
        input:checked ~ .form-field__indicator {
            background-color: $primaryBG;
            border-color: $primaryBG;
        }
        input:checked ~ .form-field__indicator {
            background: $primaryBG;
        }

        &:hover {
            .form-field__label {
                color: $primaryBG;
            }
            .form-field__indicator {
                border-color: $primaryBG;
            }
        }

        .form-field__indicator {
            border-radius: 0;
            border: 1px solid #c5c5c5;
            background: #fff;

            &::after {
                border: solid #fff;
            }
        }
    }
}

.shopping-done {
    &__icon {
        img {
            filter: brightness(0) saturate(100%) invert(82%) sepia(72%) saturate(451%) hue-rotate(21deg) brightness(86%)
                contrast(95%);
        }
    }

    &__title {
        color: #00AC39;
    }

    &__text {
        color: #004CAB;
    }
    &__next {
        background: $secondaryBG;
        color: $secondaryFG;
        border-radius: 0;
        font-weight: bold !important;
        text-transform: uppercase !important;

        &:hover {
            background: $primaryBG;
            color: $primaryFG;

            img {
                filter: brightness(0) saturate(100%) invert(97%) sepia(0%) saturate(7483%) hue-rotate(313deg)
                    brightness(104%) contrast(104%); //#fff
            }
        }
    }
}
